window.addEventListener("load", init);
window.addEventListener("resize", init);

function init() {
  const cards = document.querySelectorAll(".boothThumbnails-card");
  const newCards = Array.prototype.slice.call(cards,0);
  newCards.forEach(function(el){
    const numOfChildImages = el.querySelectorAll("img:not(:first-of-type)").length;
    if (numOfChildImages > 0) {
      const ClientRect = el.getBoundingClientRect();
      const width = ClientRect.width;
      const parts = width / numOfChildImages;

      el.addEventListener("mousemove", function(e) {
        const windowLeft = el.getBoundingClientRect();
        const xPos = e.pageX - windowLeft.left;
        thumbnailRemoveClass();

        switch (numOfChildImages) {
          case 1:
            if (xPos > 0 && xPos <= parts) {
              thumbnailAddClass(el, 1);
            }
            break;
          case 2:
            if (xPos > 0 && xPos <= parts) {
             thumbnailAddClass(el, 1);
            } else if (xPos > parts && xPos <= parts * 2) {
              thumbnailAddClass(el, 2);
            }
            break;
          case 3:
            if (xPos > 0 && xPos <= parts) {
              thumbnailAddClass(el, 1);
            } else if (xPos > parts && xPos <= parts * 2) {
              thumbnailAddClass(el, 2);
            } else if (xPos > parts * 2 && xPos <= parts * 3) {
              thumbnailAddClass(el, 3);
            }
            break;
          case 4:
            if (xPos > 0 && xPos <= parts) {
              thumbnailAddClass(el, 1);
            } else if (xPos > parts && xPos <= parts * 2) {
              thumbnailAddClass(el, 2);
            } else if (xPos > parts * 2 && xPos <= parts * 3) {
              thumbnailAddClass(el, 3);
            } else if (xPos > parts * 3 && xPos <= parts * 4) {
              thumbnailAddClass(el, 4);
            }
            break;
        }
      });
    }

    el.addEventListener("mouseleave", function() {
      thumbnailRemoveClass();
    });
  });
}

function thumbnailAddClass(parent, index) {
  $(parent).children('img')[index].classList.add("is-visible");
}

function thumbnailRemoveClass() {
  if (document.querySelector("img.is-visible")) {
    document.querySelector("img.is-visible").classList.remove("is-visible");
  }
}